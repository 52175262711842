import { render, staticRenderFns } from "./Explain.vue?vue&type=template&id=7bfd728e&scoped=true&"
import script from "./Explain.vue?vue&type=script&lang=js&"
export * from "./Explain.vue?vue&type=script&lang=js&"
import style0 from "./Explain.vue?vue&type=style&index=0&id=7bfd728e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bfd728e",
  null
  
)

export default component.exports
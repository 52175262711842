<template>
  <div class="explain-cont">
    <div class="name">{{info.termName}}</div>
    <div class="cont" v-html="info.termDetail">
      
    </div>
    <div class="footer">
      <div class="form-back" @click="goBack">返回</div>
      <div class="form-sub" @click="goOk">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['rowid'],
  data () {
    return {
      id: '',
      info: {},
    }
  },
  watch: {
    rowid () {
      if(this.rowid){
        this.id = this.rowid;
        this.getInfo()
      }
    }
  },
  mounted () {
    if(this.rowid){
      this.id = this.rowid;
      this.getInfo()
    }
  },
  methods: {
    getInfo () {
      const vm = this;
      let postData = {
        serviceLicenceId: this.id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/contractService/queryServiceTerms", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.info = response.data.body
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    goBack() {
      this.$emit('hide')
    },
    goOk () {
      this.$emit('hide', true)
    }
  },
}
</script>

<style lang="scss" scoped>
.explain-cont{
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 200;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.name{
  padding: 20px 0;
  font-size: 42px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 58px;
}
.cont{
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 52px;
  p{
    margin-bottom: 20px;
  }
}
.footer{
  display: flex;
  align-items: center;
  padding-top: 50px;
}
.form-sub{
  width: 280px;
  height: 80px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 80px;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  border: 6px solid #1F955B;
}
.form-back{
  width: 200px;
  height: 80px;
  background: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 80px;
  color: #1F955B;
  margin: 0 auto;
  margin-top: 30px;
  border: 6px solid #1F955B;
}
</style>
